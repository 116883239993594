//* Font Sizes

// $h1--s: 40px;
// $h1--m: 44px;
// $h1--l: 48px;

// $h2--s: 60px;
// $h2--m: 80px;
// $h2--l: 130px;

//* Module Code

.module--headline {
  @include hyphenate();
  text-align: center;
  position: relative;
  padding-bottom: 40px;
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    height: 10px;
    max-width: $col3;
    width: 90%;
    background-color: $colorC;
    border-radius: 5px;
  }
}

.headline__main-title {
  max-width: 100%;
  color: $colorC;
  font-size: #{fluid(30px, 48px)};

  // font-size: rem($h1--s);
  line-height: calc(120/130);
  margin-bottom: 10px;

  @media (min-width: $headline_break-m) {
    // font-size: rem($h1--m);
  }

   @media (min-width: $headline_break--l) {
    // font-size: rem($h1--l);
  }
}

.headline__sub-title {
  max-width: 100%;
  @include hyphenate();
  font-size: #{fluid(54px, 130px)};
  line-height: calc(20/48);
  @include letterSpacingXD(48, 10);
  // font-size: rem($h2--s);
  line-height: calc(120/130);
  @include letterSpacingXD(120, 10);

  span {
    display: block;
    font-family: $sofia-font;
    // font-size: rem($h2--s);
    line-height: calc(120/130);
    @include letterSpacingXD(120, 10);
  }

  @media (min-width: $headline_break-m) {
    // font-size: rem($h2--m);
  }

   @media (min-width: $headline_break--l) {
    // font-size: rem($h2--l);
  }

}

.signal--headline {
  display: block;
  width: 272px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}