.module--projectfilter {
  padding: 40px 0;
  position: relative;
  margin-bottom: 80px;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    height: 4px;
    background-color: $colorC;
    border-radius: 2px;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 6px;
    background-color: $colorC;
    border-radius: 3px;
  }

}