$navTransform: all 0.3s ease;

.navtoggle {
  padding: 19px 15px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  background-color: $colorC;
  cursor: pointer;
}

.hamburger {
  display: block;
  cursor: pointer;
  width: 40px;
  height: 30px;
  position: relative;
}

.hamburger span {
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: $colorB;
  border-radius: 2px;
  left: 0;
  transition: $navTransform;
}

.hamburger span:nth-child(1),
.hamburger span:nth-child(3) {
  top: 13px;
}

//* the plus
.hamburger span:nth-child(3) {
  transform: rotate(90deg);
  opacity: 0;
}

.hamburger span:nth-child(2) {
  top: 0;
}

.hamburger span:nth-child(4) {
  top: 26px;
}

/* Animation */

.navtoggle.active {

  background-color: $colorB;
  .hamburger {
    span {
      background-color: $colorC;
    }
    span:nth-child(2),
    span:nth-child(4) {
      // width: 0;
      opacity: 0;
    }
    span:nth-child(3) {
      // top: 13px;
      // transform: rotate(90deg);
      opacity: 1;
    }
  }

}