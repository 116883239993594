

.module--kontakt {

}

.kontakt__text {
  @extend %hyphenate;
  font-family: $sofia-font;
  // font-size: rem(38px);
  font-size: #{fluid(30px, 55px)};
  line-height: calc(55/50);
  @include letterSpacingXD(55, 10);
  margin-bottom: 50px;

  strong {
    font-family: $shrikhand-font;
    font-size: rem(38px);
    font-size: #{fluid(30px, 38px)};
    line-height: calc(50/40);
    @include letterSpacingXD(50 , 10);
    font-weight: $shrikhand-regular;
  }

  p {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a:hover {
    text-decoration: underline;
  }

  @media (min-width: $kontakt_break--s) {

    font-size: rem(48px);

    strong {
      font-size: rem(42px);
    }

  }

}


@media (min-width: $kontakt_break--l) {
  .module--kontakt {
    display: flex;
    justify-content: space-between;
  }

  .kontakt__text {
    margin-bottom: 0;
  }

  .kontakt__social {
    margin-left: 25px;
  }

}