$alert_h--l: 150px;
$alert_w--l: 171px;
$alert_border--l: 18px;

$alert_padding--l: 90px;

.module--alert {
  padding-top: calc($alert_padding--l - calc($alert_border--l/2));
  @extend %hyphenate;
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
  font-weight: 800;
  // font-size: rem(95px);
  font-size: #{fluid(40px, 95px)};
  line-height: calc(95/95);
  @include letterSpacingXD(95, 10);

  @media(min-width:$general_break--xs) {

  }

}

.alert-box {
  position: relative;
  background-color: $colorD;
  border: 10px solid $colorB;
  border-radius: $alert_border--l;
  padding: 64px 24px 30px 24px;
  &:after {
    content: "";
    position: absolute;
    top: -54px;
    left: 50%;
    width: 100px;
    aspect-ratio: 171/150;
    transform: translateX(-50%);
    background-image: url(/theme/assets/dist/images/alert.svg);
    background-size: cover;
    @media (min-width: 500px) {

    }
  }

  @media(min-width:400px) {
    padding: 64px 40px 30px 40px;
  }

  @media(min-width:600px) {
    border: 15px solid $colorB;
    padding: 84px 80px 55px 80px;
    &:after {
      width: 171px;
      top: -90px;
    }
  }

}