.leistungen__link {
  .svg-lei-cb {
    transition: fill .3s ease;
    fill: #2B2D3B;
  }
  .svg-lei-cs {
    transition: fill .3s ease;
    fill: #DEF243;
  }

  &:hover {
    .svg-lei-cb {
      fill: #C7D8EF;
    }
    .svg-lei-cs {
      fill: #1515FA;
    }
  }

}

.signal--headline {
  .svg-lei-cb {
      fill: #C7D8EF;
    }
    .svg-lei-cs {
      fill: #1515FA;
    }
}