body {
  font-family: $shrikhand-font;
  color: $colorB;
}

::-moz-selection {
  background: $colorC;
}

::selection {
  background: $colorC;
}

a {
  transition: color 0.3s ease;
}