.subline {
  @include hyphenate();
  text-align: center;
  color: $colorC;
  font-size: rem(40px);
  line-height: calc(48/48);
  @include letterSpacingXD(48, 10);
  margin-bottom: 5px;
  span {
    display: block;
    color: $colorB;
    font-size: rem(50px);
  line-height: calc(100/100);
  @include letterSpacingXD(100, 10);
  }

  @media (min-width: $headline_break-m) {
    font-size: rem(44px);
    span {
      font-size: rem(70px);
    }
  }

  @media (min-width: $headline_break--l) {
    font-size: rem(48px);
    span {
      font-size: rem(100px);
    }
  }

}