.navtoggle {
  z-index: 20;
  position: absolute;
  top: $content-padding;
  right: $content-padding;
}



.globalheader__logowrap {
  width: $col2;
}

.globalheader__logolink {
  display: block;
  max-width: 142px;
}

.globalnav {
  z-index: 10;
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: $colorC;
  padding: 120px 24px 24px 24px;
  transition: all 0.3s ease;
  &.open {
    top: 0;
    max-height: 100%;
    overflow: visible;
  }
}

@media (min-width: $nav_break--s) {

  .navtoggle {
    display: none;
  }

  .wrapper--globalnav {
    display: flex;
    justify-content: space-between;
  }

  .globalnav {
    z-index: revert;
    position: revert;
    top: revert;
    left: revert;
    width: revert;
    max-height: revert;
    overflow: revert;
    background-color: revert;
    padding: revert;
    transition: all 0.3s ease;
    &.open {
      top: revert;
      max-height: revert;
      overflow: revert;
    }
  }

  .globalheader__logowrap {
    width: percentage(calc($col3/$col12));
  }

  .home {
    .globalheader__logo {
      display: none;
    }
  }

  .globalheader__logo {
    max-width: 142px;
  }

  .globalnav {
    width: percentage(calc($col9/$col12));
  }

}