body {
  background-color: $colorA;
  min-height: 100vh;
}

.wrap {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

.stage {
  max-width: 100%;
}

.wrapper {
  width: 100%;
  max-width: $col12 + 2*$content-padding;
  padding: 0 $content-padding;
  margin: 0 auto;
}

.wrapper--m {
  width: 100%;
  max-width: $col10;
  margin: 0 auto;
}

.wrapper--s {
  width: 100%;
  max-width: $col8;
  margin: 0 auto;
}

//* Vertical



//!DEV

// .globalheader {
//   border: 1px solid blue;
// }

// .stage {
//   border: 1px solid tomato;
// }

// .globalfooter {
//   border: 1px solid green;
// }