$logoH: 96.36;
$logoW: 142;

.ratio {
  display: block;
  position: relative;
  aspect-ratio: 1/1;
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  display: block;
}

.ratio--logo {
  aspect-ratio: calc($logoW / $logoH);
}