// ! Medium Breakpoint

.globalfooter {
  margin-top: 120px;
  padding: 33px 0 78px 0;
}

.globalfooter__inner {
  // border: 1px solid red;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "logo logo"
    "adress adress"
    "social social"
    "nav nav"
    "link link";
  row-gap: 50px;
  column-gap: 20px;

  @media (min-width: $footer_break--m) {
    // border: 1px solid green;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 15px;
    grid-template-areas:
    "logo logo social social"
    "adress adress nav link";
    }

  @media (min-width: $footer_break--l) {
    // border: 1px solid blue;
    grid-template-columns: 2fr 3.5fr 2.5fr 2fr 2fr;
    grid-template-rows: auto;
    grid-template-areas:
      "logo adress social nav link";
    column-gap: percentage(calc($gut/$col12));
    row-gap: 0;
  }

}

.globalfooter__logo {
  grid-area: logo;
}

.globalfooter__adress {
  grid-area: adress;
}

.globalfooter__social {
  grid-area: social;
}

.globalfooter__nav {
  grid-area: nav;
}

.globalfooter__links {
  grid-area: link;
}