.project-meta__title {
  position: relative;
  padding-bottom: 40px;
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 10px;
    max-width: $col3;
    width: 90%;
    background-color: $colorC;
    border-radius: 5px;
  }
}

.project-meta__main-title {
  color: $colorC;
  font-size: #{fluid(30px, 40px)};
  line-height: calc(48/40);
  @include letterSpacingXD(48, 10);
  margin-bottom: 20px;
}

.project-meta__sub-title {
  @include hyphenate();
  font-size: #{fluid(54px, 100px)};
  line-height: calc(120/130);
  @include letterSpacingXD(120, 10);

  span {
    display: block;
    font-family: $sofia-font;
    font-size: #{fluid(54px, 60px)};
    line-height: calc(70/60);
    @include letterSpacingXD(700, 10);
    font-weight: $sofia-bold;
  }

}

.project--2col {
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: $pagenav_break) {
    grid-template-columns: 1fr 1fr;
    column-gap: $gut;
  }
}

.project-meta__content {
  row-gap: $gut;
}

.project-meta__content {


  h3 {
    font-size: rem(24px);
    line-height: calc(35/24);
    @include letterSpacingXD(35, 10);
  }

  p, ul {
    font-family: $sofia-font;
    font-size: rem(30px);
    line-height: calc(35/30);
    @include letterSpacingXD(35, 10);
  }

  p + h3 {
    margin-top: $gut;
  }

}