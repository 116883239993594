.social__list {
  display: grid;
}

.social__link {
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  transition: all  0.3s ease-in-out;
  background-size: cover;
  &:hover {
    transform: scale(1.1);
  }
}

//* Colors
.social__list--black {
  .social__link__mail {
    background-image: url('/theme/assets/dist/images/Icon_Mail_black.svg');
  }
  .social__link__phone {
    background-image: url('/theme/assets/dist/images/Icon_Phone_black.svg');
  }
  .social__link__facebook {
    background-image: url('/theme/assets/dist/images/Icon_facebook_black.svg');
  }
  .social__link__instagram {
    background-image: url('/theme/assets/dist/images/Icon_Insta_black.svg');
  }
  .social__link__linkedin {
    background-image: url('/theme/assets/dist/images/Icon_LinkedIn_black.svg');
  }
}

.social__list--blue {
  .social__link__mail {
    background-image: url('/theme/assets/dist/images/Icon_Mail.svg');
  }
  .social__link__phone {
    background-image: url('/theme/assets/dist/images/Icon_Phone.svg');
  }
  .social__link__facebook {
    background-image: url('/theme/assets/dist/images/Icon_facebook.svg');
  }
  .social__link__instagram {
    background-image: url('/theme/assets/dist/images/Icon_Insta.svg');
  }
  .social__link__linkedin {
    background-image: url('/theme/assets/dist/images/Icon_LinkedIn.svg');
  }
}

//* Context

.social__list--footer {
  grid-template-columns: 74px 74px;
  column-gap: 15px;
  row-gap: 15px;

  @media (min-width: $footer_break--s) {
    grid-template-columns: 74px 74px 74px 74px;
  }

  @media (min-width: $footer_break--m) {
    // grid-template-columns: 74px 74px;
  }

  @media (min-width: $footer_break--l) {
    grid-template-columns: 74px 74px;
  }

  .social__link {
    width: 74px;
    height: 74px;
  }

}

.social__list--team {

  .social__link {
    width: 60px;
    height: 60px;
  }

  max-width: 100%;
  grid-template-columns: 60px 60px 60px;
  column-gap: 15px;
  row-gap: 15px;

  @media (min-width: 400px) {
    grid-template-columns: 60px 60px 60px 60px 60px;
  }

  @media (min-width: 550px) {
    .social__link {
      width: 90px;
      height: 90px;
    }
    grid-template-columns: 90px 90px 90px 90px 90px;
  }

  // @media (min-width: $team_break--l) {
  //   grid-template-columns: 103px 103px 103px 103px 103px;
  // }
}

.social__list--contact {
  grid-template-columns: 122px 122px;
  column-gap: 25px;
  row-gap: 25px;
  .social__link {
    width: 122px;
    height: 122px;
  }
}

@media (min-width: $kontakt_break--s) {

  .social__list--contact {
    grid-template-columns: 122px 122px 122px 122px;
  }

}

@media (min-width: $kontakt_break--l) {

  .social__list--contact {
    grid-template-columns: 122px 122px;
  }

}