$leistungen_break--m: 450px;
$leistungen_break--l: 710px;

.leistungen__list {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 80px;
  @media (min-width: $pagenav_break) {
    grid-template-columns: 1fr 1fr;
    column-gap: $gut;
  }
}

.leistungen__link {

}

.signal--nav {
    width: 80%;
    max-width: 272px;
    margin: 0 auto 13px auto;
    // transition: all  0.3s ease-in-out;

    &:hover {
      // transform: scale(1.1);
    }
}


.leistungen__text {
  position: relative;
  padding-bottom: 36px;
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    height: 10px;
    max-width: 80%;
    width: 214px;
    background-color: $colorC;
    border-radius: 5px;
  }
  text-align: center;
  font-size: rem(44px);
  line-height: calc(53/53);
  @include letterSpacingXD(53, 10);
  @include hyphenate();
  span {
    display: block;
    font-family: $sofia-font;
    font-size: rem(52px);
    line-height: calc(53/62);
    @include letterSpacingXD(53, 10);
  }

  @media (min-width: $leistungen_break--m) {
    font-size: rem(53px);
    span {
      font-size: rem(62px);
    }
  }

}