.team__image {
  position: relative;
  margin-bottom: 35px;
  display: block;
}

.team__image__image {
  width: 100%;
  height: auto;
}

.team__image__caption {
  @extend %hyphenate;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // font-size: rem(40px);
  font-size: #{fluid(40px, 97px)};
  line-height: calc(90/97);
  @include letterSpacingXD(90, 10);
  // @media (min-width: 500px) {
  //   font-size: rem(97px);
  // }
}

.team__info {
  margin-bottom: 32px;
}

.team__name {
  // font-size: rem(40px);
  font-size: #{fluid(30px, 40px)};
  line-height: calc(45/40);
  @include letterSpacingXD(45, 10);
}

.team__function {
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
  // font-size: rem(45px);
  font-size: #{fluid(35px, 45px)};
  line-height: calc(45/45);
  @include letterSpacingXD(45, 10);
}

.team__social {
  margin-bottom: 40px;
  @media (min-width: $vertical_break--s) {
    margin-bottom: 60px;
  }
}

.team__text {
  @extend %hyphenate;
}