//* VARIABLES

 //* Accordionn sizes
$button-xs: 100px;
$button-s: 120px;
$button-m: 160px;
$button-l: 210px;

$button-font-xs: 70px;
$button-font-s: 110px;
$button-font-m: 140px;
$button-font-l: 180px;

$arrow_h-xs: 40px;
$arrow_h-s: 68px;
$arrow_h-m: 98px;
$arrow_h-l: 108px;
$arrow_w-l: 66px;
$button_padding-l: 48px;

 //* Breakpoints
$accordion_break--s: 400px;
$accordion_break--m: 700px;
$accordion_break--l: 980px;

//* GRID

.accordion__title {
  display: grid;
  grid-template-columns: 1fr $arrow_h-xs;
  grid-template-areas:
    "title_number title_number "
    "title_text title_arrow";
  // column-gap: 48px;
  div {
    align-self: center;
  }

  @media (min-width: $accordion_break--s) {
    grid-template-columns: 1fr $arrow_h-s;
  }

  @media (min-width: $accordion_break--m) {
    grid-template-columns: $button-m 1fr $arrow_h-m;
    grid-template-areas:
    "title_number title_text title_arrow";
  }

   @media (min-width: $accordion_break--l) {
    grid-template-columns: $button-l 1fr $arrow_h-l;
   }

}

.title_number {
  grid-area: title_number;
}

.title_text {
  grid-area: title_text;

}

.title_arrow {
  grid-area: title_arrow;
}

//* AREAS

.module--accordion {
  position: relative;
  @include hyphenate();
  padding: 135px 0 0 0;
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    aspect-ratio: 277/82;
    width: 277px;
    transform: translateX(-50%);
    background-image: url(/theme/assets/dist/images/angel.svg);
    background-size: cover;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  h3 {
    font-family: 'Pirata One', cursive;
    font-size: #{fluid(60px, 130px)};
    line-height: calc(132/141);
    text-align: center;
    margin-bottom: 77px;

    span {
      display: block;
      font-family: 'Shrikhand', cursive;
      font-size: #{fluid(26px, 39px)};
      line-height: calc(39/39);
      @include letterSpacingXD(39, 100);
    }
  }
}

.accordion_element {
  position: relative;
  // padding-bottom: 15px;
  margin-bottom: 100px;
  &:last-child {
    margin-bottom: 0;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    height: 10px;
    max-width: $col3;
    width: 90%;
    background-color: $colorC;
    border-radius: 5px;
  }

  // border: 1px solid red;

  @media (min-width: $accordion_break--s) {
    // border: 1px solid blue;
  }

  @media (min-width: $accordion_break--m) {
    // border: 1px solid yellow;
    margin-bottom: 170px;
  }

  @media (min-width: $accordion_break--l) {
    // border: 1px solid cyan;
  }

}

//* TITLE DETAILS

.accordion__title {
  cursor: pointer;
  padding-bottom: 30px;
  @media (min-width: $accordion_break--s) {
    padding-bottom: 40px;
  }
  @media (min-width: $accordion_break--l) {
    padding-bottom: 30px;
  }
}

//* NUMBER BUTTON

.title_number {
  transition: all  0.3s ease-in-out;
  span {
    display: block;
    text-align: center;
    aspect-ratio: 1/1;
    background-color: $colorD;
    border-radius: 50%;
    font-family: 'Pirata One', cursive;
    width: $button-xs;
    line-height: $button-xs;
    font-size: rem($button-font-xs);

    margin: 0 auto 20px auto;

    @media (min-width: $accordion_break--s) {
      width: $button-s;
      line-height: $button-s;
      font-size: rem($button-font-s);
      margin: 0 auto 22px auto;
    }

    @media (min-width: $accordion_break--m) {
      width: $button-m;
      line-height: $button-m;
      font-size: rem($button-font-m);
      margin: 0 auto;
    }

    @media (min-width: $accordion_break--l) {
      width: $button-l;
      line-height: $button-l;
      font-size: rem($button-font-l);
    }

  }
}



//* ARROW ICON

.title_arrow__icon {
  margin: 0 auto;
  display: block;
  transform-origin: 50% 50%;
  transition: transform 0.15s ease;
  aspect-ratio: 66/108;
  height: $arrow_h-xs;
  background-image: url(/theme/assets/dist/images/arrrow.svg);
  background-size: cover;

  @media (min-width: $accordion_break--s) {
    height: $arrow_h-s;
  }
  @media (min-width: $accordion_break--m) {
    height: $arrow_h-m;
  }
  @media (min-width: $accordion_break--l) {
    height: $arrow_h-l;
  }
}

.accordion__title:hover .title_number {
  transform: scale(1.1);
}

.accordion__title.is-active {
  .title_arrow__icon {
    transform: rotate(90deg);
  }
}

  .accordion__title {
    &:hover {
      .title_arrow__icon {

      }
    }
  }

//* TYPOGRAPHY

.title_text {
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
  font-weight: 800;
  font-size: #{fluid(36px, 80px)};
  line-height: calc(80/80);
  @include letterSpacingXD(80, 10);
  padding: 0 20px 0 0;

  @media (min-width: $accordion_break--m) {
    padding: 0 20px 0 $button_padding-l;
  }

}

.accordion__text {
  // border: 1px solid green;
  @extend %hyphenate;
  font-size: #{fluid(22px, 40px)};
  line-height: calc(48/40);
  @include letterSpacingXD(40, 10);
  display: none;
  padding: 0 0 45px 0;
  @media (min-width: $accordion_break--m) {
    padding: 0 $col1 45px calc($button-l + $button_padding-l);
  }
  &.is-open {
  }

  p {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

}

//* LISTS

.list-points, .list-dash {
  ul {
    all: revert;
    margin-top: 0;
    margin-bottom: 16px;
    font-family: 'Sofia Sans Extra Condensed', sans-serif;
    font-weight: 400;
    font-size: #{fluid(29px, 45px)};
    line-height: 1.2;
    @include letterSpacingXD(45, 10);
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list-points {
  ul {
    list-style-type: "...";
  }
}

.list-dash {
  ul {
    list-style-type: "- ";
  }
}