.globalheader {
  padding-top: 24px;
  margin-bottom: 40px;
}

// .home .globalheader {
//   margin-bottom: 80px;
// }

.module--headline {
  margin-bottom: 80px;
  &.module--headline--signal {
    margin-bottom: 48px;
  }
}

.signal--headline {
  margin-bottom: 74px;
}

.home .globalheader,
.module--pagenav,
.module--bild,
.module--text,
.project-meta__title,
.module--project-meta,
.module--projectsoverwiew {
  margin-bottom: 60px;
  @media (min-width: $vertical_break--s) {
    margin-bottom: 80px;
  }
}

.paginierung {
  margin-bottom: 40px;
  @media (min-width: $vertical_break--s) {
    margin-bottom: 60px;
  }
}

// .module--bild {
//   margin-bottom: 68px;
// }



.module--team {
  margin-bottom: 80px;
  @media (min-width: $vertical_break--s) {
    margin-bottom:120px;
  }
}

.module--alert {
  margin-bottom: 111px;
}

.module--leistungen {
  margin-bottom: 280px;
}

.module--kacheln {
  margin-bottom: 68px;
}

.module--kontakt {
  margin-bottom: 68px;
}

.stage-wrap {
  .module:last-child {
    margin-bottom: 0;
  }
}