//* Fonts
$shrikhand-font: 'Shrikhand', cursive;
$sofia-font: 'Sofia Sans Extra Condensed', sans-serif;
$pirata-font: 'Pirata One', cursive;

//* Weights
$shrikhand-regular: 400;
$sofia-regular: 400;
$sofia-bold: 600;
$sofia-extrabold: 800;
$sofia-black: 900;
$pirata-extrabold: 400;