.globalfooter {
  background-color: $colorB;
  color: $colorD;
  font-family: $sofia-font;
  font-size: rem(25px);
  line-height: calc(30/25);
  @include letterSpacingXD(30, 10);

  h2 {
    font-family: 'Shrikhand', cursive;
    font-size: rem(30px);
  line-height: calc(30/20);
  @include letterSpacingXD(30, 10);
  }

  a:hover {
    text-decoration: underline;
  }

}

.globalfooter__logolink {
  display: block;
  max-width: 165px;
}

@media (min-width: $footer_break--l) {

  .globalfooter__logolink {
    max-width: 122px;
  }

}