.paginierung {
  background-color: $colorC;
  padding: 25px 17px;
}

.paginierung__list {
  display: flex;
  justify-content: space-between;
}

.paginierung__link {
  display: flex;
  align-items: center;
}

.paginierung__link--next {
  flex-direction: row-reverse;
}

.paginierung__text {
  font-family: $sofia-font;
  font-size: rem(25px);
  @include letterSpacingXD(50 , 10);
}

.paginierung__arrow {
  aspect-ratio: 49/41;
  width: 49px;
  background-image: url(/theme/assets/dist/images/paginierung.svg);
  background-size: cover;
  width: 49px;
}

.paginierung__link--prev {
  .paginierung__arrow {
    margin-right: 18px;
  }
}

.paginierung__link--next {
  text-align: right;
  .paginierung__arrow {
    margin-left: 18px;
    transform: scaleX(-1);
  }
}