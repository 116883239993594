.pagenav {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $gut;
  @media (min-width: $pagenav_break) {
    grid-template-columns: 1fr 1fr;
    column-gap: $gut;
  }
}

.pagenav__item {
  overflow: hidden;
}

.pagenav__link {
  display: block;
  position: relative;
  aspect-ratio: 1;
}

.pagenav svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  display: block;
  transition: transform 0.5s ease;
}

.pagenav__label {
  @extend %hyphenate;
  font-size: rem(30px);
  line-height: 1;
  @include letterSpacingXD(30, 10);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: red;
  padding: 0 10px 20px 10px;
  text-align: center;
  transition: transform 0.3s ease;
  // transform-origin: 50% 80%;
}

//! Color Schemes

//* Black / Yellow
.pagenav__item--a {
  background-color: $colorD;
  .pagenav__label {
    color: $colorB;
  }
}

//* Lila / Blue
.pagenav__item--b {
  background-color: $colorE;
  .pagenav__label {
    color: $colorC;
  }
}

//* Yellow / Black
.pagenav__item--c {
  background-color: $colorB;
  .pagenav__label {
    color: $colorD;
  }
}
