.project__text {
  row-gap: 30px;

  @include hyphenate();
  font-size: rem(20px);
  line-height: calc(30/20);
  @include letterSpacingXD(30, 10);

  p {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }

}