.wrapper--logo {
  img {
    display: block;
    width: 100%;
  }
}

.logo {
  margin-top: 18px;
}

.logo__icon {
  display: none;
}

.claim {
  margin-top: 20px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    height: 4px;
    background-color: $colorC;
    border-radius: 2px;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 6px;
    background-color: $colorC;
    border-radius: 3px;
  }
}

@media (min-width: $logo_break--s) {

  .claim {
    &:before {
      height: 8px;
      border-radius: 4px;
    }
    &:after {
      height: 12px;
      border-radius: 6px;
    }
  }
}

@media (min-width: $logo_break--l) {

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 40px;
  }

  .logo__icon {
    display: block;
    width: percentage(calc(332/1002));
  }

  .logo__name {
    width: percentage(calc(618/1002));
  }

  .claim {
    margin-top: 50px;
    &:before {
      height: 8px;
      border-radius: 4px;
    }
    &:after {
      height: 16px;
      border-radius: 8px;
    }
  }
}