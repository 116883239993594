$star-l: 46px;
$star-spacing-l: 40px;

.stars {
  position: relative;
  padding-top: $star-l;
  &:before {
    z-index: 2;
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: $star-l;
    width: $star-l;
    transform: translateX(-50%);
    background-image: url(/theme/assets/dist/images/star.svg);
    background-size: cover;
  }

  p {
    position: relative;
    z-index: 1;
    padding-top: $star-spacing-l;
    padding-bottom: $star-l+$star-spacing-l;
   &:after {
    z-index: 2;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: $star-l;
    width: $star-l;
    transform: translateX(-50%);
    background-image: url(/theme/assets/dist/images/star.svg);
    background-size: cover;
  }
  }


}