// ! No Hyphen bis 400px

$kachel_break--m: 500px;
$kachel_break--l: 800px;

.kacheln__list {
  margin-top: 100px;
}

.kacheln__item {
  flex-direction: row-reverse;
  color: $colorB;
  background-color: $colorD;

  &:nth-child(even) {
    flex-direction: row;
    color: $colorD;
    background-color: $colorB;
  }
}

.kacheln__text,
.kacheln__image {
  width: 100%;
  aspect-ratio: 1/1;
}

.kacheln__text {
  // @extend %hyphenate;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    position: relative;
    padding-bottom: 20px;
    font-size: rem(50px);
    line-height: calc(65/60);
    @include letterSpacingXD(65, 10);



    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      height: 6px;
      max-width: 150px;
      width: 80%;
      background-color: $colorC;
      border-radius: 3px;
    }

    // @media (min-width: $kachel_break--m) {
    //   font-size: rem(60px);
    // }

      @media(min-width:480px) {
        font-size: rem(60px);
      }

      @media(min-width:600px) {
        font-size: rem(36px);
      }

      @media(min-width:720px) {
        font-size: rem(52px);
      }

      @media(min-width:775px) {
        font-size: rem(54px);
      }

    @media(min-width:870px) {
      font-size: rem(60px);
    }

  }

}

// @media (min-width: $kachel_break--l) {
@media (min-width: 600px) {

  .kacheln__item {
    display: flex;
  }

  .kacheln__text,
  .kacheln__image {
    width: 50%;
  }

}