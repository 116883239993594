.kunden__list {
  position: relative;
  padding: 40px 0;
  margin-top: 60px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 4px;
    width: 150px;
    max-width: 80%;
    background-color: $colorC;
    border-radius: 2px;
  }

  &:before {
    top: 0px;
  }
  &:after {
    bottom: 0px;
  }

}

.kunden__item {
  @include hyphenate();
  font-size: #{fluid(22px, 40px)};
  line-height: calc(48/40);
  @include letterSpacingXD(48, 10);
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}