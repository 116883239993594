$project-overview-break_m: 400px;
$project-overview-break_l: 600px;

.projects__list {
  display: flex;
  flex-wrap: wrap;
  gap: percentage(calc($gut/$col12));
}

.projects__item {
  margin-bottom: 40px;
  // transition: opacity 1s;

  figcaption {

    padding: 10px 0 0 10px;

    font-size: rem(20px);
    line-height: calc(25/20);
    @include letterSpacingXD(25, 10);

    span {
      display: block;
      font-family: $sofia-font;
      font-size: rem(25px);
      line-height: 1;
      @include letterSpacingXD(25, 10);
    }
  }

}

.projects__item--landscape {
  width: percentage(calc($col12/$col12));
  @media (min-width: $project-overview-break_l) {
    width: percentage(calc($col8/$col12));
  }
}

.projects__item--portrait {
  width: percentage(calc($col12/$col12));
  @media (min-width: $project-overview-break_m) {
    width: percentage(calc($col6/$col12));
  }
  @media (min-width: $project-overview-break_l) {
    width: percentage(calc($col4/$col12));
  }
}