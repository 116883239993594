.btn-group {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 36px;
  column-gap: $gut;
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
}

.btn {

  @include hyphenate();
  font-size: rem(30px);
  line-height: calc(34/30);
  @include letterSpacingXD(30, 10);
  span {
    pointer-events: none;
    display: block;
    font-family: $sofia-font;
    font-size: rem(40px);
    line-height: calc(44/40);
    @include letterSpacingXD(44, 10);
  }

  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding-left: 74px;
  min-height: 60px;

  &:before {
    content: "";
    aspect-ratio: 1/1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 50px;
    background-color: #C7D8F0;
    border-radius: 50%;
    z-index: 2;
  }

  &.active {
    &:after {
      content: "";
      aspect-ratio: 43/37;
      position: absolute;
      top: 44%;
      transform: translateY(-50%);
      left: 10px;
      width: 43px;
      background-image: url(/theme/assets/dist/images/check.svg);
      background-size: cover;
      z-index: 3;
    }
  }

}