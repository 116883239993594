.globalnav__item {
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 24px;
  font-size: rem(38px);
  line-height: 1;
  @include letterSpacingXD(38, 10);
  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 4px;
    background-color: $colorB;
    border-radius: 2px;
  }
}

.globalnav__link {
  // font-size: rem(38px);
  // line-height: 1;
  // @include letterSpacingXD(38, 10);
}

@media (min-width: $nav_break--s) {

  .globalnav__list {
    display: flex;
    justify-content: space-between;
  }

  .globalnav__item {
    position: revert;
    padding-bottom: revert;
    margin-bottom: revert;
    &:after {
      all: revert;
    }
  }

  .globalnav__link {
    // font-size: rem(19px);
    // @include letterSpacingXD(19 , 10);
    // line-height: 1;
    &:hover {
      color: $colorC;
    }
  }

  .globalnav__item {
    font-size: rem(19px);
    @include letterSpacingXD(19 , 10);
    line-height: 1;
    &.is-current {
      .globalnav__link {
        color: $colorC;
      }
    }
  }

}