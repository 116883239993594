.flowtext {
  @include hyphenate();
  font-size: #{fluid(22px, 40px)};
  line-height: calc(48/40);
  @include letterSpacingXD(40, 10);

  @media (min-width: $text_break--m) {
    @include nohyphenate();
  }
}

.flowtext--full {
  @include hyphenate();
  font-family: $sofia-font;
  font-size: #{fluid(30px, 40px)};
  line-height: calc(44/40);
  @include letterSpacingXD(44, 10);

  h3 {
    font-family: $shrikhand-font;
    margin-bottom: 2px;
  }

  p, ul {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    all: revert;
    list-style-type: "- ";
  }

  p + h3,
  ul + h3 {
    margin-top: 40px;
  }

  a:hover {
    text-decoration: underline;
  }

  @media (min-width: $text_break--m) {
    @include nohyphenate();
  }
}