/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default Styles */
body,
h1,
h2,
h3,
h4,
p,
a,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd,
button {
  all: unset;
  display: revert;
}

/* Bring back the Pointers */
a {
  cursor: pointer;
}

/* Remove list styles on ul, ol elements */
ul,
ol {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* fix the feature of 'hidden' attribute.
display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}