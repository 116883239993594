.project__img {
  img {
    // width: 100%;
    // max-width: 100%;
    height: auto;
  }
  figcaption {
    font-family: $sofia-font;
    font-size: rem(25px);
    line-height: 1;
    @include letterSpacingXD(25, 10);
    padding: 10px 0 0 10px;
  }
  margin-bottom: 40px;
}

.project__img {
    row-gap: 40px;
}